/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

:root {
  --primary-color: #6F0A28;
  --secondary-color: #B11548;
  --third-color: #D1C499;
  --fourth-color: #E8E3DF ;
  --white-color: #ffff;
  --black-color: #000;
}


.logos-veracruz {
  display: block;
  width: 100%;
  height: 4rem;
  background: url(./assets/pleca-ver.svg) no-repeat center;
  background-size: contain;
}

.headerC h1{
  width: 100%;
  display: flex;
  justify-content: end;
  background-color: var(--fourth-color);
  color : var(--primary-color) !important;
  padding: 10px;
  margin-bottom: 5px;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: white !important;
  border-radius: 5px !important;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-color);
}
.btn-dark{
  background-color: var(--primary-color) !important;
  border-color: transparent !important;
}
.my-dialog-container .mat-dialog-container {
  padding: 0;
}

.mat-dialog-content {
  margin: 0px !important;
}

.mat-dialog-title {
  margin: 16px 32px !important;
  padding: 4px !important;
  border-bottom: 3px solid var(--primary-color);
  display: inline-block !important;
}

.mat-dialog-actions {
  background-color: rgb(229, 228, 228);

  margin: 0 !important;
  padding: 8px !important;

  border-top: 1px solid rgba(177, 177, 177, 0.5);
}
.actions {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-content: center;
  align-items: flex-end;
  height: 100%;
}
